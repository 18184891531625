import { inject, Ref } from 'vue'
import type { InjectionKey } from 'vue'

export const SIDEBAR_CONTEXT_KEY = Symbol() as InjectionKey<{
  isSidebarOpen: Readonly<Ref<boolean>>
  toggleSidebarVisibility: () => void
}>

export function useSidebar() {
  const ctx = inject(SIDEBAR_CONTEXT_KEY)

  if (!ctx) throw new TypeError('No sidebar context provided!')

  return ctx
}
