import config from '../config'
import {
  QuantistryLegacyAPIClient,
  BaseHttpRequest,
  OpenAPIConfig,
  CancelablePromise,
  ApiError,
} from '@quantistry/legacy-api'
// import { QuantistryAPIClient } from '@quantistry/api'
import { request } from '@quantistry/legacy-api/core/request'
import { ApiRequestOptions } from '@quantistry/legacy-api/core/ApiRequestOptions'
import { captureException } from '@sentry/vue'
import { useStore } from '~/store/useStore'

const LOGIN_PAGE_PATHNAME = '/auth/login'

/**
 * This function gets called on all ApiErrors thrown by the QuantistryAPIClient
 */
async function onApiError(error: ApiError) {
  captureException(error, {
    level: error.status >= 500 ? 'error' : 'warning',
    tags: {
      api: true,
      'http.status': error.status,
      'http.method': error.request.method,
      // sanitize all UUIDs
      'http.route': new URL(error.url).pathname.replace(/[a-fA-F0-9]{32}/g, '{id}'),
    },
  })

  const store = useStore()
  const redirectToLoginPage =
    store.state.isAuthenticated && error.status === 401 && location.pathname !== LOGIN_PAGE_PATHNAME

  if (redirectToLoginPage) {
    const currentURL = new URL(location.href)
    const newURL = new URL(LOGIN_PAGE_PATHNAME, location.origin)
    newURL.searchParams.set('from', `${currentURL.pathname}${currentURL.search}`)

    // Go to the login page with a page reload to clear all state easily
    location.href = newURL.href

    return new Promise(() => {
      // Keep the promise hanging since we reload the page anyways
      // and don't want to render intermediate errors.
    })
  }
}

/**
 * This rather cumbersome way allows us to globally intercept
 * requests issued by our QuantistryAPIClient.
 */
class InterceptedFetchHttpRequest extends BaseHttpRequest {
  constructor(config: OpenAPIConfig) {
    super(config)
  }

  request<T>(options: ApiRequestOptions) {
    return new CancelablePromise<T>(async (resolve, reject, onCancel) => {
      try {
        if (!onCancel.isCancelled) {
          const promise = request<T>(this.config, options)
          onCancel(() => promise.cancel())
          const response = await promise
          resolve(response)
        }
      } catch (error) {
        if (error instanceof ApiError) {
          await onApiError(error)
        }
        reject(error)
      }
    })
  }
}

export const legacyApi = new QuantistryLegacyAPIClient(
  {
    // we use cookies
    WITH_CREDENTIALS: true,
    BASE: config.apiUrl.toString(),
  },
  InterceptedFetchHttpRequest
)

// The new API is not quite ready yet
// export const api = new QuantistryAPIClient(
//   {
//     // we use cookies
//     WITH_CREDENTIALS: true,
//     BASE: config.apiUrl.toString(),
//   },
//   InterceptedFetchHttpRequest
// )
