<template>
  <div v-if="isDataReady" class="shell min-h-full w-full">
    <div
      class="resizable-container"
      :class="{
        'w-[260px]': isSidebarOpen,
        'w-0': !isSidebarOpen,
        invisible: !isSidebarOpen && !isSidebarAnimating,
      }"
      :style="{
        '--sidebar-transition-duration': `${transitionDuration}ms`,
      }"
    >
      <NavigationSidebar
        :favorite-projects="favs"
        :organizations="organizations"
        :show-internal-tools="shouldShowInternalNav"
        @contact-support="openContactForm"
        @create-project="openCreateProject"
        @toggle-visibility="isSidebarOpen = !isSidebarOpen"
        @show-internal-affiliation-selector="isInternalAffiliationSelectorOpen = true"
      />
    </div>

    <router-view />
  </div>
  <div v-else class="flex h-screen w-full items-center justify-center">
    <HexagonSpinner :size="32" />
  </div>

  <Onboarding
    v-if="!!route.query.onboarding"
    @close="router.replace({ query: { onboarding: undefined } })"
  />

  <ContactFormModal
    v-if="isContactFormLoaded"
    :is-open="isContactFormOpen"
    @on-request-close="closeContactForm"
  />

  <CreateProjectModal
    v-if="isCreateProjectLoaded"
    :is-open="!!createProjectForAffiliationId"
    :affiliation-id="createProjectForAffiliationId!"
    @close="closeCreateProject"
  />

  <InternalAffiliationSelector
    v-if="isInternalAffiliationSelectorOpen"
    is-open
    @on-request-close="isInternalAffiliationSelectorOpen = false"
  />
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, provide, readonly, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAffiliations } from '~/api/affiliations'
import { useStore } from '~/store/useStore'
import NavigationSidebar from './NavigationSidebar/NavigationSidebar.vue'
import { useFavoriteProjects, formatProject } from '~/api/projects'
import { useStorage } from '@vueuse/core'
import { SIDEBAR_CONTEXT_KEY } from './NavigationSidebar/use-sidebar'
import { useMatomo } from '~/utils/tracking'
import HexagonSpinner from './HexagonSpinner.vue'

const ContactFormModal = defineAsyncComponent(() => import('./ContactFormModal.vue'))
const CreateProjectModal = defineAsyncComponent(() => import('./CreateProjectModal.vue'))
const InternalAffiliationSelector = defineAsyncComponent(
  () => import('~/components/InternalAffiliationSelector.vue')
)
const Onboarding = defineAsyncComponent(() => import('./Onboarding/Onboarding.vue'))

const store = useStore()
const router = useRouter()
const route = useRoute()
const isSidebarOpen = useStorage(`${store.state.user.userID}-sidebar_open`, true)
const isSidebarAnimating = ref(false)
const isContactFormLoaded = ref(false)
const isContactFormOpen = ref(false)
const isInternalAffiliationSelectorOpen = ref(false)
const isCreateProjectLoaded = ref(false)
const createProjectForAffiliationId = ref<string | null>(null)

const matomo = useMatomo()

function toggleSidebarVisibility() {
  matomo.trackEvent('navigation-sidebar', isSidebarOpen.value ? 'hide' : 'show')
  isSidebarOpen.value = !isSidebarOpen.value
}

provide(SIDEBAR_CONTEXT_KEY, {
  isSidebarOpen: readonly(isSidebarOpen),
  toggleSidebarVisibility,
})

const transitionDuration = 250
watch(isSidebarOpen, (current, previous, onCleanup) => {
  isSidebarAnimating.value = true
  // make sure to keep in sync with the duration specified in CSS below
  const timeout = setTimeout(() => {
    isSidebarAnimating.value = false
  }, transitionDuration)

  onCleanup(() => clearTimeout(timeout))
})

function openContactForm() {
  isContactFormLoaded.value = true
  isContactFormOpen.value = true
}

function closeContactForm() {
  isContactFormOpen.value = false
}

function openCreateProject(affiliationID: string) {
  isCreateProjectLoaded.value = true
  createProjectForAffiliationId.value = affiliationID
}

function closeCreateProject() {
  createProjectForAffiliationId.value = null
  refetch()
}

const { affiliations, organizations, projectsByAffiliation, affiliationsByProject, refetch } =
  useAffiliations()

const { data: favoriteProjects } = useFavoriteProjects()
const favs = computed(() => {
  if (!affiliationsByProject.value) {
    return []
  }
  return (
    favoriteProjects.value?.list.map((project) => {
      // Note: dangerous usage of !
      // But, we know better than TS that every project has an associated affiliation
      // eslint-disable-next-line
      const { affiliationID } = affiliationsByProject.value!.get(project.projectID)!
      return formatProject(project, affiliationID, { addFavoriteSuffix: true })
    }) ?? []
  )
})

const isDataReady = computed(
  () =>
    affiliations.value !== undefined &&
    projectsByAffiliation.value !== undefined &&
    favoriteProjects.value !== undefined
)

const shouldShowInternalNav = computed(() => {
  const internalRoles = ['support', 'admin']
  const role = store.state.user.role
  return internalRoles.includes(role as string)
})
</script>

<style scoped>
.shell {
  display: grid;
  grid-template-columns: auto 1fr;
}

.resizable-container {
  @apply flex justify-end;

  transition: width var(--sidebar-transition-duration) ease-out;
}
</style>
