<template>
  <div class="grid place-items-center" :style="{ width: `${size}px`, height: `${size}px` }">
    <div class="spinner" :style="`--spinner-scale: ${scale}; --spinner-size: ${DEFAULT_SIZE}px`" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const DEFAULT_SIZE = 24

const props = withDefaults(defineProps<{ size?: number }>(), { size: 20 })
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>

<style scoped lang="postcss">
.spinner {
  width: var(--spinner-size);
  height: var(--spinner-size);
  scale: var(--spinner-scale);
  rotate: -15deg;
  clip-path: path(
    evenodd,
    'M17.892 7.443 11.5 3.753a1 1 0 0 0-1 0l-6.392 3.69a1 1 0 0 0-.5.866v7.382a1 1 0 0 0 .5.866l6.392 3.69a1 1 0 0 0 1 0l6.392-3.69a1 1 0 0 0 .5-.866V8.309a1 1 0 0 0-.5-.866ZM13 1.155a4 4 0 0 0-4 0l-6.392 3.69a4 4 0 0 0-2 3.464v7.382a4 4 0 0 0 2 3.464L9 22.845a4 4 0 0 0 4 0l6.392-3.69a4 4 0 0 0 2-3.464V8.309a4 4 0 0 0-2-3.464L13 1.155Z'
  );
  background-color: transparent;

  &::before {
    @apply animate-spin;

    animation-duration: 1s;
    scale: 1.2;
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    background: conic-gradient(
      rgb(61 147 245 / 100%),
      rgb(61 147 245 / 40%) 180deg,
      rgb(61 147 245 / 100%)
    );
  }
}
</style>
