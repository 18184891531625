import { computed, ComputedRef, Ref, ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import ColorHash from 'color-hash'
import { useStore } from '../store/useStore'
import { legacyApi } from './quantistry'
import { Project, ProjectMetadata } from '@quantistry/legacy-api'
import { useAffiliationById, useCurrentAffiliationId } from './affiliations'
import { useQuery } from '@tanstack/vue-query'

const generateColor = new ColorHash({ lightness: 0.6 })

export type ProjectListParameters = Parameters<typeof legacyApi.projects.projectsReadAll>[0]

export function generateProjectColor(
  project: ProjectMetadata | { projectID: string; name: string }
) {
  return generateColor.hex(`${project.name}${project.projectID}`.toLowerCase())
}

export function formatProject(
  project: Project,
  affiliationID: string,
  { addFavoriteSuffix } = {
    /**
     * Add URL suffix indicating that the link is coming from the
     * favorites section in the navigation sidebar
     */
    addFavoriteSuffix: false,
  }
) {
  const basePath = `/${affiliationID}/${project.projectID}`
  const href = addFavoriteSuffix ? `${basePath}-fav` : basePath

  return {
    id: project.projectID,
    name: project.name,
    color: generateProjectColor(project),
    href,
  }
}

function stripFavoriteSuffix(projectId: string) {
  return projectId.replace(/-fav$/, '')
}

export function useCurrentProjectId() {
  const route = useRoute()
  const currentProjectId = computed(() =>
    route.params.projectId ? stripFavoriteSuffix(route.params.projectId as string) : undefined
  )
  return currentProjectId
}

export function useCurrentProject() {
  const currentProjectId = useCurrentProjectId()
  const { data, refetch, error } = useQuery({
    staleTime: 1000 * 60,
    enabled: computed(() => !!currentProjectId.value),
    retry: false, // failed queries will not retry by default
    refetchOnWindowFocus: false,
    queryKey: ['project', currentProjectId],
    queryFn() {
      return legacyApi.projects.projectsRead({ projectId: currentProjectId.value as string })
    },
  })

  return {
    currentProject: data,
    refetch,
    error,
  }
}

export function useCanEditProjectsInAffiliation(affiliationId: Ref<string | undefined>) {
  const { affiliation } = useAffiliationById(affiliationId)
  const { state } = useStore()
  const canEditProjects = computed(() => {
    const me = affiliation.value?.members?.find((member) => member.userID === state.user.userID)

    if (me) {
      return me.role === 'admin' || me.role === 'owner'
    }

    return false
  })

  return { canEditProjects }
}

/**
 * Returns projects for an affiliation.
 * Falls back to the current affiliation when no explicit `affiliationId` is passed.
 */
export function useProjects(parameters?: ComputedRef<ProjectListParameters>) {
  const currentAffiliationId = useCurrentAffiliationId()
  const selectedAffilationId = computed(
    () => parameters?.value.affiliationId || currentAffiliationId.value
  )

  const defaultParameters = { offset: 0, sortby: 'name', order: 'asc' } as ProjectListParameters

  const params = computed<ProjectListParameters>(() => {
    return Object.assign({}, defaultParameters, parameters?.value, {
      affiliationId: selectedAffilationId.value,
    })
  })

  const { data, refetch, isFetching } = useQuery({
    staleTime: 1000 * 60,
    enabled: computed(() => !!selectedAffilationId.value),
    retry: false, // failed queries will not retry by default
    refetchOnWindowFocus: false,
    queryKey: ['projects', selectedAffilationId, params],
    async queryFn() {
      return legacyApi.projects.projectsReadAll(params.value)
    },
  })
  const projectsRef = computed(() => data.value?.list)
  const total = computed(() => data.value?.total_count)

  return {
    isFetching,
    projects: projectsRef,
    total,
    fetchProjects: refetch,
  }
}

export function useFavoriteProjects() {
  return useQuery({
    staleTime: 1000,
    retry: false, // failed queries will not retry by default
    refetchOnWindowFocus: false,
    queryKey: ['favorite-projects'],
    queryFn() {
      return legacyApi.projects.projectsReadAllFavorites({
        sortby: 'name',
        order: 'asc',
      })
    },
  })
}

export function useTemplateProjects() {
  const store = useStore()
  const { user } = store.state
  return {
    templateProjects: user.template_projects,
    templateProjectIds: user.template_projects?.map((project) => project.projectID as string),
  }
}

export function useWorkflowNrPerProject(
  projectIds: ComputedRef<Project['projectID'][] | undefined>
) {
  const projectWorkflowsNr = ref()
  watchEffect(async () => {
    if (!projectIds.value) {
      return
    }
    const workflowsNrPromises = projectIds.value.map((id) =>
      legacyApi.workflows.workflowsReadAll({ projectId: [id], limit: 0 })
    )
    try {
      const responses = await Promise.allSettled(workflowsNrPromises)
      const projectNrs: { [key: string]: number | undefined } = {}
      responses.forEach((response, index) => {
        const projectId = projectIds.value?.[index]
        const count = response.status === 'fulfilled' ? response.value.total_count : undefined
        if (projectId) {
          projectNrs[projectId] = count
        }
      })
      projectWorkflowsNr.value = projectNrs
    } catch (e) {
      //TODO: handle error
    }
  })
  return { projectWorkflowsNr }
}
