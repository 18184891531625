import merge from 'lodash/merge'
import { reactive } from 'vue'
import debug from 'debug'
import { UserWithAffiliations, ApiError } from '@quantistry/legacy-api'
import { legacyApi } from '../api/quantistry'
import { identifyUser } from '../utils/hubspot'
import * as Sentry from '@sentry/browser'

const log = debug('app:store')

class Store {
  state = reactive({
    isAuthenticated: false,
    user: {} as UserWithAffiliations,
  })

  async initialize() {
    try {
      await this.fetchUser()
      this.state.isAuthenticated = true
    } catch {
      this.state.isAuthenticated = false
    }
  }

  async checkAuthentication() {
    try {
      await legacyApi.authentication.authenticationRefresh()
      log(`Successfully refreshed access token.`)
      this.state.isAuthenticated = true
    } catch (error) {
      if (error instanceof ApiError && error.status === 401) {
        // @TODO??
      }
      this.state.isAuthenticated = false
    }
  }

  async fetchUser() {
    const user = await legacyApi.account.accountRead()
    this.state.user = user
    identifyUser({ email: user.email as string, id: user.userID as string })
    Sentry.setUser({ email: user.email, id: user.userID })
  }

  async updateUser(userData: Partial<UserWithAffiliations>) {
    // optimistic updates
    this.state.user = merge({}, this.state.user, userData)

    const user = await legacyApi.account.accountUpdate({ requestBody: userData })

    this.state.user = user
  }

  async changePassword(passwords: { password: string; new_password: string }) {
    await legacyApi.account.accountChangePassword({
      requestBody: passwords,
    })
  }
}

const store = new Store()

export function useStore() {
  return store
}
