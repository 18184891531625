import { AffiliationMetadata, Project } from '@quantistry/legacy-api'
import { computed, Ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '../store/useStore'
import { legacyApi } from './quantistry'
import { formatProject } from './projects'
import { useQuery } from '@tanstack/vue-query'

export function useCurrentAffiliationId() {
  const route = useRoute()
  const currentAffiliationId = computed(() => route.params.affiliationId as string | undefined)
  return currentAffiliationId
}

export function useAffiliationById(affiliationId: Ref<string | undefined>) {
  const store = useStore()
  const { data, refetch, error, isFetching } = useQuery({
    staleTime: 1000 * 60,
    enabled: computed(() => !!affiliationId.value),
    retry: false, // failed queries will not retry by default
    refetchOnWindowFocus: false,
    queryKey: ['affiliation', affiliationId],
    async queryFn() {
      const data = await legacyApi.affiliations.affiliationsRead({
        affiliationId: affiliationId.value as string,
      })

      if (data.affiliationID === store.state.user.personal_affiliationID) {
        data.name = 'Personal Account'
      }

      return data
    },
  })

  return {
    isFetching,
    affiliation: data,
    refetch,
    error,
  }
}

export function useCurrentAffiliation() {
  const store = useStore()
  const currentAffiliationId = useCurrentAffiliationId()
  const key = computed(() =>
    store.state.isAuthenticated && currentAffiliationId.value
      ? currentAffiliationId.value
      : undefined
  )

  const { affiliation, refetch, error, isFetching } = useAffiliationById(key)

  return {
    isFetching,
    currentAffiliation: affiliation,
    refetch,
    error,
  }
}

export function useAffiliations() {
  const store = useStore()
  const affiliationsRef = computed(() => {
    if (store.state.user.personal_affiliation && store.state.user.affiliations) {
      const personalAffiliation = Object.assign(store.state.user.personal_affiliation, {
        name: 'Personal Account',
      })
      const allAffiliations = [personalAffiliation, ...store.state.user.affiliations]

      return allAffiliations
    }
    return store.state.user.affiliations
  })

  const { data, refetch } = useQuery({
    staleTime: 1000 * 60,
    queryKey: ['all-projects'],
    retry: false, // failed queries will not retry by default
    refetchOnWindowFocus: false,
    async queryFn() {
      const allProjects = await Promise.all(
        affiliationsRef.value!.map(async (affiliation) => {
          const projects = await legacyApi.projects.projectsReadAll({
            sortby: 'name',
            order: 'asc',
            affiliationId: affiliation.affiliationID as string,
          })
          return {
            affiliation,
            projects: projects.list,
          }
        })
      )

      return allProjects
    },
  })

  /*
   * The id of default project created by the backend
   * matches the affiliation id
   */
  const defaultProjectIds = computed(() => {
    return data.value?.flatMap((item) =>
      item.projects
        .filter((project) => project.projectID === item.affiliation.affiliationID)
        .map((project) => project.projectID)
    )
  })

  const { data: defaultProjectsData } = useQuery({
    staleTime: 1000 * 60,
    queryKey: ['all-default-projects', defaultProjectIds],
    enabled: computed(() => !!defaultProjectIds.value),
    refetchOnWindowFocus: false,
    retry: false, // failed queries will not retry by default
    async queryFn() {
      const allDefaultProjects = await Promise.all(
        defaultProjectIds.value!.map(async (projectId) => {
          const workflowsNrRes = await legacyApi.workflows.workflowsReadAll({
            projectId: [projectId],
            limit: 0,
          })
          const compoundsNrRes = await legacyApi.systems.systemsReadAll({
            projectId: [projectId],
            limit: 0,
          })
          return {
            projectId,
            workflowsNr: workflowsNrRes.total_count,
            compoundsNr: compoundsNrRes.total_count,
          }
        })
      )

      return allDefaultProjects
    },
  })

  /*
   * We exclude the default projects created by the backend
   * That have no workflows and no compounds
   */
  const emptyDefaultProjectIds = computed(() => {
    return defaultProjectsData.value
      ?.filter((project) => project.compoundsNr === 0 && project.workflowsNr === 0)
      .map((project) => project.projectId)
  })

  function excludeEmptyDefaultProjects(projects: Project[]) {
    return projects.filter((project) => {
      return !emptyDefaultProjectIds.value?.includes(project.projectID)
    })
  }

  const affiliationsByProject = computed(() =>
    data.value
      ? new Map(
          data.value.flatMap((item) =>
            item.projects.map((project) => [
              project.projectID,
              item.affiliation as Required<AffiliationMetadata>,
            ])
          )
        )
      : undefined
  )

  const projectsByAffiliation = computed(() =>
    data.value
      ? new Map(
          data.value.map((item) => [
            item.affiliation.affiliationID!,
            excludeEmptyDefaultProjects(item.projects),
          ])
        )
      : undefined
  )

  const organizations = computed(
    () =>
      affiliationsRef.value?.map((affiliation) => ({
        id: affiliation.affiliationID as string,
        name: affiliation.name as string,
        projects:
          projectsByAffiliation.value
            ?.get(affiliation.affiliationID!)
            ?.map((project) => formatProject(project, affiliation.affiliationID!)) ?? [],
      })) ?? []
  )

  return {
    /**
     * Map of affiliationID -> list of projects in that affiliation
     */
    projectsByAffiliation,
    /**
     * Map of projectID -> affiliation metadata
     */
    affiliationsByProject,
    /**
     * Projects grouped by affiliation
     */
    organizations,
    affiliations: affiliationsRef,
    /*
     * Default projects created by the backend
     * that have no workflows and no compounds
     */
    emptyDefaultProjectIds,
    fetchAffiliations: store.fetchUser.bind(store),
    refetch,
  }
}
