<template>
  <button class="q-focus group -my-2 -mr-2 ml-auto flex-shrink-0 rounded-md p-2">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      class="group-hover:fill-primary-400 fill-transparent transition-colors"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
    >
      <rect x="0.5" y="0.5" width="15" height="15" rx="4.38" stroke="#3d93f5" />
      <path
        d="M8 3.5V12.5"
        class="stroke-primary-400 transition-colors group-hover:stroke-white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 8H3.5"
        class="stroke-primary-400 transition-colors group-hover:stroke-white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</template>
