<template>
  <nav
    aria-label="Main Navigation"
    class="sticky top-0 flex h-screen w-[260px] flex-col border-r border-neutral-200 bg-white"
  >
    <header
      class="isolate flex h-[var(--header-height)] shrink-0 items-center justify-between border-b border-neutral-200 px-5"
    >
      <router-link to="/" class="q-focus flex flex-shrink-0 items-center">
        <img
          src="../../assets/quantistry-logo-transparent.svg?url"
          class="h-10 w-10 shrink-0"
          width="40"
          height="40"
          alt=""
        />
        <div class="font-logo text-md ml-2 font-bold text-neutral-700">
          Quantistry
          <sub class="text-primary-500 ml-[0.2ch]">Lab</sub>
        </div>
      </router-link>

      <Tooltip>
        <template #trigger="{ triggerProps }">
          <IconButton
            aria-label="Hide sidebar"
            v-bind="triggerProps"
            @click.prevent="$emit('toggle-visibility')"
          >
            <CollapseIcon />
          </IconButton>
        </template>
        <template #tooltip>Hide sidebar</template>
      </Tooltip>
      <a
        class="q-focus hover:bg-primary-600 active:bg-primary-700 bg-primary-500 text-primary-50 absolute bottom-0 left-1/2 z-10 -translate-x-1/2 translate-y-[calc(100%+1px)] whitespace-nowrap rounded-t px-2 py-1 text-sm transition-transform focus:translate-y-0"
        href="#main"
      >
        Skip to main content
      </a>
    </header>

    <section class="isolate mb-4 flex flex-col gap-y-0.5 bg-white px-5 pt-5">
      <router-link v-slot="{ isExactActive, href, navigate }" to="/" custom>
        <ListButton
          as="a"
          :href="href"
          variant="primary"
          :selected="isExactActive"
          @click="navigate"
        >
          <template #icon-before><HomeIcon /></template>
          Home
        </ListButton>
      </router-link>
    </section>

    <section class="flex flex-1 flex-col gap-y-4 overflow-y-auto overscroll-contain px-5">
      <NavigationSidebarList
        v-if="showInternalTools"
        id="internal-tools"
        :items="internalTools"
        :is-expanded="settings.isListExpanded('internal-tools')"
        @toggle-visibility="settings.toggleList($event)"
      >
        <template #title>Internal Tools</template>
      </NavigationSidebarList>

      <NavigationSidebarList
        id="favorite-projects"
        :items="favoriteProjects"
        :is-expanded="settings.isListExpanded('favorite-projects')"
        @toggle-visibility="settings.toggleList($event)"
      >
        <template #title>
          <div class="flex items-center gap-x-1">
            <StarIcon class="h-4 w-4 text-neutral-500" role="presentation" />
            Favorite Projects
          </div>
        </template>
      </NavigationSidebarList>

      <router-link
        v-for="organization in organizations"
        :key="organization.id"
        v-slot="{ isActive, href, navigate }"
        :to="`/${organization.id}`"
        custom
      >
        <NavigationSidebarList
          :id="organization.id"
          :items="organization.projects"
          :is-expanded="settings.isListExpanded(organization.id)"
          :is-active="isActive"
          @toggle-visibility="settings.toggleList($event)"
          @create="$emit('create-project', $event)"
        >
          <template #title>
            <Tooltip>
              <template #trigger="{ triggerProps, mergeProps }">
                <a
                  :href="href"
                  class="transition-colors"
                  :class="{ 'hover:text-neutral-700': !isActive, 'text-primary-400': isActive }"
                  v-bind="mergeProps(triggerProps, { onClick: navigate })"
                >
                  {{ organization.name }}
                </a>
              </template>
              <template #tooltip>Go to team</template>
            </Tooltip>
          </template>

          <template #action-button>
            <Tooltip :gutter="2">
              <template #trigger="{ triggerProps, mergeProps }">
                <NavigationSidebarCreateProjectButton
                  v-bind="
                    mergeProps(triggerProps, {
                      onClick: () => $emit('create-project', organization.id),
                    })
                  "
                  :aria-label="`Create new project in ${organization.name}`"
                />
              </template>
              <template #tooltip>Create project</template>
            </Tooltip>
          </template>
        </NavigationSidebarList>
      </router-link>
    </section>

    <footer class="p-5">
      <button class="support-button" @click="$emit('contact-support')">
        <div class="mb-1 font-bold uppercase text-white">Need help?</div>
        <div class="text-primary-200 font-medium">Support</div>
      </button>
    </footer>
  </nav>
</template>

<script lang="ts" setup>
import HomeIcon from 'ionicons/dist/svg/home-outline.svg'
import CollapseIcon from '~/assets/collapse-icon.svg'
import StarIcon from 'ionicons/dist/svg/star.svg'
import NavigationSidebarList from './NavigationSidebarList.vue'
import { useSidebarSettings } from './use-sidebar-settings'
import IconButton from '../IconButton.vue'
import Tooltip from '../Tooltip/Tooltip.vue'
import NavigationSidebarCreateProjectButton from './NavigationSidebarCreateProjectButton.vue'
import ListButton from '../ListButton/ListButton.vue'

interface ProjectItem {
  id: string
  name: string
  color: string
  href: string
}

const props = defineProps<{
  favoriteProjects: ProjectItem[]
  organizations: { id: string; name: string; projects: ProjectItem[] }[]
  showInternalTools?: boolean
}>()

const emit = defineEmits<{
  (evt: 'contact-support'): void
  (evt: 'create-project', organizationId: string): void
  (evt: 'show-internal-affiliation-selector'): void
  (evt: 'toggle-visibility'): void
}>()

const internalTools = [
  {
    id: 'task-playground',
    name: 'Task Playground',
    href: '/task-playground',
  },
  {
    id: 'switch-orgs',
    name: 'Switch Organizations',
    action: () => emit('show-internal-affiliation-selector'),
  },
]

const defaultExpandedLists = props.organizations
  .filter((item) => item.projects.length > 0)
  .map((item) => item.id)

if (props.favoriteProjects.length) {
  defaultExpandedLists.push('favorite-projects')
}
const settings = useSidebarSettings({ defaultExpandedLists })
</script>

<style scoped>
.support-button {
  @apply bg-primary-400 q-focus flex h-[120px] w-full flex-col items-start justify-center rounded-lg p-2 transition-all hover:shadow-md;

  background-image: url('../../assets/support-illustration.svg?url'),
    url('../../assets/cards-background.svg?url'),
    linear-gradient(116.3deg, #1e72d2 -18.2%, #3d93f5 40.2%);
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 60%, 50%, 100%;
  background-position: bottom right, top -6px left -14px, top left;
}
</style>
