import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import { registerMatomo } from './utils/tracking'
import { useStore } from './store/useStore'
import config from './config'
import { initChat } from './utils/hubspot'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { VueQueryPlugin } from '@tanstack/vue-query'

async function main() {
  const store = useStore()
  const app = createApp(App)

  if (!config.isDev) {
    Sentry.init({
      app,
      dsn: 'https://66fa3840e16c48ed8dd73384e5c2d7fa@o1343746.ingest.sentry.io/6618978',
      environment: config.deployment,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.25,
      release: config.release,
    })
  }

  await store.initialize()

  app.use(router)
  app.use(VueQueryPlugin)
  registerMatomo(app, router)

  app.mount('#app')

  // Hubspot Chat Widget
  if (config.deployment === 'prod') {
    initChat()
  }
}

main()
