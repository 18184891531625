const config = {
  apiUrl: new URL(
    '/api',
    import.meta.env.MODE === 'test' ? 'http://localhost:3000' : location.origin
  ),
  webappUrl: import.meta.env.VITE_WEBAPP_URL as string,
  matomoId: parseInt(import.meta.env.VITE_MATOMO_ID as string),
  enableTracking: import.meta.env.PROD,
  // these are injected by vite
  mode: import.meta.env.MODE,
  isProd: import.meta.env.PROD,
  isDev: import.meta.env.DEV,
  baseUrl: import.meta.env.BASE_URL,
  deployment: import.meta.env.VITE_DEPLOYMENT as 'dev' | 'stage' | 'prod' | undefined,
  release: import.meta.env.VITE_CI_COMMIT_SHA as string | undefined,
}

export type AppConfig = Readonly<typeof config>
export default config
