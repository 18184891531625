import { useStorage } from '@vueuse/core'
import { useStore } from '~/store/useStore'
import { useMatomo } from '~/utils/tracking'

export function useSidebarSettings({ defaultExpandedLists }: { defaultExpandedLists: string[] }) {
  const matomo = useMatomo()
  const { state } = useStore()
  const expandedLists = useStorage(
    `${state.user.userID}-sidebar_expanded_lists`,
    defaultExpandedLists
  )

  return {
    isListExpanded(id: string) {
      return expandedLists.value.includes(id)
    },
    toggleList(id: string) {
      if (this.isListExpanded(id)) {
        matomo.trackEvent('navigation-sidebar', 'collapse-list')
        expandedLists.value = expandedLists.value.filter((item) => item !== id)
      } else {
        matomo.trackEvent('navigation-sidebar', 'uncollapse-list')
        expandedLists.value.push(id)
      }
    },
  }
}
