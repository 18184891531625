import AppShell from './components/AppShell.vue'
import config from './config'
import { useStore } from './store/useStore'
import * as VueRouter from 'vue-router'

// `meta.title` is used solely for matomo tracking

const routes: VueRouter.RouteRecordRaw[] = [
  { path: '/index.html', redirect: '/' },
  {
    path: '/auth/login',
    component: () => import('./pages/auth/Login.vue'),
    meta: { authenticatedRedirect: '/' },
  },
  // {
  //   path: '/auth/register',
  //   component: () => import('./pages/auth/Register.vue'),
  //   meta: { authenticatedRedirect: '/' },
  // },
  {
    path: '/auth/register/confirm',
    redirect: '/auth/confirm',
    meta: { authenticatedRedirect: '/' },
  },
  {
    path: '/auth/confirm',
    component: () => import('./pages/auth/ConfirmEmail.vue'),
    meta: { authenticatedRedirect: '/' },
  },
  {
    path: '/auth/forgot-password',
    component: () => import('./pages/auth/ResetPassword.vue'),
    meta: { authenticatedRedirect: '/' },
  },
  {
    path: '/auth/change-password',
    component: () => import('./pages/auth/ChangePassword.vue'),
    meta: { authenticatedRedirect: '/' },
  },
  {
    path: '/auth/invitation',
    component: () => import('./pages/auth/Invitation.vue'),
    meta: {},
    beforeEnter(route) {
      // when there is no token, there is no point in navigating to this page
      if (!route.query.invitation_token) return '/'
    },
  },
  {
    path: '/',
    component: AppShell,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        component: () => import('./pages/home/Home.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/task-playground',
        component: () => import('./pages/TaskPlayground.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/workflows/new',
        component: () => import('./pages/workflows/Creator.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'compounds/new',
        component: () => import('./pages/compounds/Viewer.vue'),
        meta: { requiresAuth: true, title: 'compound-viewer' },
      },
      {
        path: '/w/:workflowId',
        component: () => import('./pages/WorkflowShortLink.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/:affiliationId',
        component: () => import('./pages/AffiliationDashboard.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/:affiliationId/:projectId',
        component: () => import('./pages/ProjectWrapper.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: '',
            component: () => import('./pages/projects/ProjectDashboard.vue'),
            meta: { requiresAuth: true },
            children: [
              {
                name: 'workflows',
                path: '',
                component: () => import('./pages/projects/WorkflowView.vue'),
                meta: { requiresAuth: true },
              },
              {
                name: 'compounds',
                path: 'compounds',
                component: () => import('./pages/projects/CompoundView.vue'),
                meta: { requiresAuth: true },
              },
            ],
          },
          {
            path: 'workflows/:workflowId',
            component: () => import('./pages/workflows/_id/Viewer.vue'),
            meta: { requiresAuth: true, title: 'workflow-viewer' },
          },
          {
            path: 'compounds/:compoundId',
            component: () => import('./pages/compounds/Viewer.vue'),
            meta: { requiresAuth: true, title: 'compound-viewer' },
          },
        ],
      },
      {
        path: '/account',
        component: () => import('./pages/Account.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
]

routes.push({ path: '/:pathMatch(.*)*', component: () => import('./pages/NotFound.vue') })

function createRouter() {
  const store = useStore()

  const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(config.baseUrl),
    routes,
  })

  router.beforeEach((to) => {
    if (to.meta.requiresAuth && !store.state.isAuthenticated) {
      return {
        path: '/auth/login',
        query: {
          from: to.fullPath,
        },
      }
    }
    if (to.meta.authenticatedRedirect && store.state.isAuthenticated) {
      return to.meta.authenticatedRedirect as string
    }
  })

  return router
}

export const router = createRouter()
