const hsq = (window._hsq = window._hsq || [])

export function showChat() {
  if (window.HubSpotConversations) {
    const status = window.HubSpotConversations.widget.status()
    if (!status.loaded) {
      window.HubSpotConversations.widget.load()
    } else {
      const widgetContainer = document.getElementById('hubspot-messages-iframe-container')
      widgetContainer?.style.setProperty('display', 'initial', 'important')
    }
  }
}

export function hideChat() {
  if (window.HubSpotConversations) {
    const status = window.HubSpotConversations.widget.status()
    if (status.loaded) {
      const widgetContainer = document.getElementById('hubspot-messages-iframe-container')
      widgetContainer?.style.setProperty('display', 'none', 'important')
    }
  }
}

export function identifyUser({ email, id }: { email: string; id: string }) {
  hsq.push(['identify', { email, id }])
}

export function initChat() {
  window.hsConversationsSettings = {
    loadImmediately: false,
  }

  if (!document.getElementById('hs-script-loader')) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.defer = true
    script.async = true
    script.id = 'hs-script-loader'
    script.src = '//js.hs-scripts.com/6596812.js'
    document.body.appendChild(script)
  }
}
