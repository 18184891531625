<template>
  <div>
    <header class="sticky top-0 z-10 flex items-center gap-x-1 bg-white pb-1">
      <button
        class="q-focus -m-1.5 rounded-md p-1.5 outline-none focus-visible:outline-offset-[-4px] disabled:pointer-events-none"
        :class="{
          'text-neutral-600 hover:text-neutral-700': !isActive,
          'text-primary-400 hover:text-primary-500': isActive,
        }"
        aria-label="Show or hide projects"
        :disabled="items.length === 0"
        :aria-controls="id"
        :aria-expanded="isExpanded"
        @click="$emit('toggle-visibility', id)"
      >
        <CaretForward
          class="h-4 w-4 flex-shrink-0 transition-all"
          :class="{
            'rotate-90': isExpanded,
          }"
          role="presentation"
        />
      </button>

      <div :id="`title-${id}`" class="q-title-4 truncate"><slot name="title" /></div>

      <slot name="action-button" />
    </header>

    <ul :id="id" :hidden="!isExpanded" :aria-labelledby="`title-${id}`">
      <li v-for="item in itemList" :key="item.id" class="list-item">
        <router-link
          v-if="item.href"
          v-slot="{ isActive: isItemActive, href, navigate }"
          :to="item.href"
          custom
        >
          <NavigationSidebarListButton
            :color="item.color"
            :to="href"
            :is-active="isItemActive"
            @click="navigate"
          >
            {{ item.name }}
          </NavigationSidebarListButton>
        </router-link>
        <NavigationSidebarListButton v-else :color="item.color" @click="item.action">
          {{ item.name }}
        </NavigationSidebarListButton>
      </li>
      <li v-if="items.length > 5" class="list-item">
        <button
          class="q-focus rounded-md px-3 py-[9px] text-neutral-500 transition-colors hover:text-neutral-600"
          @click="showAll = !showAll"
        >
          {{ showAll ? `Show first 5 only` : `Show all ${items.length}` }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import CaretForward from 'ionicons/dist/svg/caret-forward.svg'
import { useRoute } from 'vue-router'
import NavigationSidebarListButton from './NavigationSidebarListButton.vue'

const props = defineProps<{
  id: string
  items: {
    id: string
    name: string
    color?: string
    href?: string
    action?: (event: Event) => void
  }[]
  isExpanded: boolean
  isActive?: boolean
}>()

defineEmits<{
  (evt: 'toggle-visibility', id: string): void
}>()

const showAll = ref(false)

const itemList = computed(() => {
  if (showAll.value) {
    return props.items
  }

  return props.items.slice(0, 5)
})

const route = useRoute()

/* We might navigate to a project that is currently collapsed
 If so, we show all the projects */
watch(
  // optional `route` because `storybook-vue3-router` doesn't work properly with storybook@7-rc
  () => route?.params,
  (currentParams) => {
    if (!currentParams.projectId) {
      return
    }

    const isProjectIdInList = itemList.value.find(
      (project) => project.id === currentParams.projectId
    )
    if (!isProjectIdInList) {
      showAll.value = true
    }
  }
)
</script>

<style scoped>
.list-item {
  @apply ml-2 flex border-l border-neutral-200 pb-0.5 last:pb-0;

  &:last-child {
    @apply border-transparent;
  }

  &::before {
    content: '';

    @apply block h-full w-[10px] flex-shrink-0 self-center border-b  border-neutral-200;
  }

  &::after {
    content: '';

    @apply absolute left-0 -ml-[3px] block h-[5px] w-[5px] self-center rounded-full border border-neutral-200 bg-white;
  }

  &:last-child::before {
    content: '';

    @apply -ml-px block h-[17.5px] w-[11px] self-start border-l border-neutral-200;
  }
}
</style>
